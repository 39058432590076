import "./App.scss";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <div className="comingSoon">Coming Soon</div> */}
        <div class="neon-sign">
          <b>
            C<span>om</span>in
            <span>g S</span>o<span>o</span>n
          </b>
        </div>

        <div className="companyName">Simple Glass</div>

        <div class="coin">
          <div class="front jump"></div>
          <div class="shadow"></div>
        </div>
        <div className="contact">
          <div>
            Contact us in the mean time at:{" "}
            <a href="mailto:sales@simpleglass.us">sales@simpleglass.us</a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
